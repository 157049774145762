import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['form'];

  submitForm(event) {
    const formEl = this.formTarget;
    Rails.fire(formEl, 'submit')
  }
}
