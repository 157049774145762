import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['participants', 'subtotal'];

  connect() {
    this.formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  calculateSubtotal() {
    const selectedParticipants = this.participantsTarget.value;
    const selectedActivities = document.querySelectorAll('[id^="cart_product_ids_"]:checked');
    let subtotal = 0.0;

    if (selectedActivities.length > 0) {
      const selectedAmountCents = Array.from(selectedActivities)
        .map((e) => { return parseInt(e.dataset.value) })
        .reduce((acc, current) => { return acc + current });

      const selectedAmount = selectedAmountCents / 100.0;
      subtotal = selectedAmount * selectedParticipants;
    }

    this.subtotalTarget.innerHTML = this.formatter.format(subtotal);
  }

  toggleActivity(event) {
    this.calculateSubtotal()
  }

  participantsChanged(event) {
    this.calculateSubtotal()
  }
}
