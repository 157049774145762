import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["locationSelect"];

  connect() {
    this.duplicateDiscountCodes();
    this.filterExistingSelects();
  }

  locationChanged(event) {
    const locationSelect = event.target;
    this.updateLocationSelect(locationSelect);
  }

  // Filter a location's discount codes based on the selected location
  updateLocationSelect(locationSelect) {
    const locationId = locationSelect.value;
    const locationName =
      locationSelect.options[locationSelect.selectedIndex].text;

    const locationOptions = this.allDiscountCodeSelect.querySelectorAll(
      `optgroup[label^="${locationName}"] option`,
    );

    const parentList = locationSelect.closest("ol");
    const discountCodeSelect = parentList.querySelector(
      "select[name$='[discount_type_id]']",
    );

    discountCodeSelect.innerHTML = "";
    locationOptions.forEach((option) => {
      const newOption = option.cloneNode(true);
      discountCodeSelect.appendChild(newOption);
    });
  }

  filterExistingSelects() {
    this.locationSelects.forEach((locationSelect) => {
      this.updateLocationSelect(locationSelect);
    });
  }

  // Copy the list of all locations and their discount codes to a new select
  // element that will be hidden and disabled. We'll use this to populate the
  // discount code select elements in the form for each location.
  duplicateDiscountCodes(event) {
    const addAnotherButton = this.element.querySelector("a.has_many_add");
    const html = addAnotherButton.dataset.html;
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const discountCodeSelect = doc.querySelector(
      "select[name$='[discount_type_id]']",
    );
    const options = discountCodeSelect.querySelectorAll("option");

    discountCodeSelect.id = "location_discount_codes_discount_type_id";
    discountCodeSelect.hidden = true;
    discountCodeSelect.disabled = true;

    document.body.appendChild(discountCodeSelect);
  }

  get allDiscountCodeSelect() {
    return document.getElementById("location_discount_codes_discount_type_id");
  }

  get locationSelects() {
    return document.querySelectorAll("select[name$='[location_id]']");
  }
}
