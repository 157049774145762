import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'container', 'toggleable' ];

  connect() {
    this.className = this.data.get('name');
  }

  update(event) {
    if (this.data.has('exclusive')) {
      this.toggleAll(this.toggleableTargets, true);

      if (event.currentTarget.dataset.toggleSelector) {
        this.useToggleSelectors();
      } else {
        this.toggleTarget(event.currentTarget);
      }
    } else {
      this.toggleAll(this.toggleableTargets);
    }
  }

  toggleAll(targets, setTo) {
    targets.forEach( toggleable => {
      this.toggleTarget(toggleable, setTo);
    });
  }

  toggleTarget(target, setTo ) {
    if (setTo !== undefined) {
      target.classList.toggle(this.className, setTo);
    } else {
      target.classList.toggle(this.className);
    }
  }

  useToggleSelectors() {
    var toggleSelector = event.currentTarget.dataset.toggleSelector;
    if (event.currentTarget.tagName == 'SELECT') {
      toggleSelector = event.currentTarget
        .options[event.currentTarget.selectedIndex]
        .dataset.toggleSelector;
    }

    var target = this.containerTarget.querySelector(toggleSelector);
    this.toggleTarget(target);
  }
}
