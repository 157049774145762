// Support component names relative to this directory:

import MicroModal from 'micromodal';
import Rails from "@rails/ujs";
Rails.start()

import 'controllers'

const initModals = (event) => {
  MicroModal.init({
    disableFocus: true,
    debugMode: process.env.RAILS_ENV == 'development',
    onShow: (modal, el, event) => {
      event.preventDefault()
    }
  });
}

document.addEventListener('DOMContentLoaded', initModals)
document.addEventListener('ajax:success', initModals)
document.addEventListener('initModals', initModals)
