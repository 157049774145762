import { Controller } from 'stimulus';
import Litepicker from 'litepicker';

export default class extends Controller {
  static targets = ['dateField', 'textField'];

  connect() {
    this.useDatePicker()
  }

  dateSelected(date) {
    const event = new CustomEvent("dateSelected")
    this.textFieldTarget.dispatchEvent(event)
  }

  connectWithDiscovery() {
    const hasNativeDateField = this.checkDateInput();

    if (hasNativeDateField) {
      this.useNativeDatePicker()
    } else {
      this.useDatePicker()
    }
  }

  useNativeDatePicker() {
    this.textFieldTarget.parentNode.removeChild(this.textFieldTarget)
  }

  useDatePicker() {
    this.textFieldTarget.classList.remove('hidden');

    const datePickerOptions = {
      element: this.textFieldTarget,
      format: 'MM/DD/YYYY',
      firstDay: 0,
    }

    if (this.data.has('minDate')) { datePickerOptions.minDate = this.data.get('minDate') }
    if (this.data.has('maxDate')) { datePickerOptions.maxDate = this.data.get('maxDate') }

    this.picker = new Litepicker(datePickerOptions)
    this.picker.on('selected', this.dateSelected.bind(this))
  }

  checkDateInput() {
    var input = document.createElement('input');
    input.setAttribute('type','date');

    var notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);

    return (input.value !== notADateValue);
  }
}
