import Glider from "glider-js";
import { Controller } from "stimulus";
import 'glider-js/glider.min.css';

export default class extends Controller {
  static targets = ['carousel', 'previous', 'next'];

  static DEFAULT_INTERVAL = 3000;

  connect() {
    this.initCarousel();

    if (this.data.has('autoscroll')) {
      this.startAutoScroll(this.data.get('autoscroll'));
    }
  }

  get gliderConfig() {
    return {
      slidesToShow: 1,
      draggable: true,
      scrollLock: true,
      rewind: true,
      arrows: {
        prev: this.previousTarget,
        next: this.nextTarget
      }
    }
  }

  initCarousel() {
    this.glider = new Glider(this.carouselTarget,this. gliderConfig);
  }

  startAutoScroll(intervalString) {
    const interval = parseInt(intervalString) || this.constructor.DEFAULT_INTERVAL;
    this.scheduleNextScroll(interval);

    this.carouselTarget.addEventListener('glider-animated', () => {
      clearTimeout(this.timeout);
      this.scheduleNextScroll(interval);
    });
  }

  scheduleNextScroll(interval) {
    this.timeout = setTimeout(this.scrollNext.bind(this), interval);
  }

  scrollNext() {
    this.glider.scrollItem('next');
  }
}
